type filter = {
  key: string
  label: string
}

/**
 * Hardocded filters for jobs.
 *
 * @param string id
 * @returns array of filter options
 */

export default function useJobFilter(id: string): Array<filter> {
  const jobFields = [
    { key: 'all', label: 'All fields' },
    { key: 'art', label: 'Art & Animation' },
    { key: 'data', label: 'Data & Research' },
    { key: 'legal', label: 'Finance & Legal' },
    { key: 'game', label: 'Game & UX Design' },
    { key: 'marketing', label: 'Marketing & Publishing' },
    { key: 'people', label: 'People Teams' },
    { key: 'product', label: 'Product & Production' },
    { key: 'sales', label: 'Sales & Business Development' },
    { key: 'technology', label: 'Technology & Development' },
    { key: 'video', label: 'Video Production' }
  ]

  const locations = [
    { key: 'all', label: 'All locations' },
    { key: 'ljubljana', label: 'Ljubljana, Slovenia' },
    { key: 'limassol', label: 'Limassol, Cyprus' },
    { key: 'shanghai', label: 'Shanghai, China' }
  ]

  const jobTypes = [
    { key: 'all', label: 'All employment types' },
    { key: 'full_time', label: 'Full-time' },
    { key: 'limited_time', label: 'Limited time' },
    { key: 'internship', label: 'Internship' },
    { key: 'student', label: 'Student' }
  ]

  if (id === 'field') return jobFields
  else if (id === 'location') return locations
  else if (id === 'type') return jobTypes
}
